.page_invitation
{
    height: 100%;

    .header
    {
        background: none;
    }

    main
    {
        height: 100%;

    }
}

#invitation_map
{
    max-width: 462px;
    width:     100%;
    height:    250px;

    .gm-style
    {
        & > div:nth-child(2), & > div:nth-child(4), & > div:nth-child(6),
        & > div:nth-child(8), & > div:nth-child(10)
        {
            display: none;
        }

    }
}

.invitation
{
    position: relative;

    @include media('t-ipad')
    {
        height: 100%;
    }

    .invitation_map-wrap
    {

        margin-bottom: 26px;

        @include media('d-only-s')
        {
            padding-left: 45px;
        }
    }

    .section_content
    {
        position:        relative;
        z-index:         1;

        display:         flex;
        align-items:     flex-start;
        justify-content: center;
        flex-direction:  column;

        min-height:      100%;

        background:      $blue;
    }

    .section_titles
    {
        display:         flex;
        flex:            1;
        justify-content: flex-end;
        flex-direction:  column;
        width:           100%;
        max-width:       1440px;

        padding:         100px 20px 20px 20px;

        @include media('t-ipad')
        {
            height:          auto;
            justify-content: flex-end;
            align-items:     flex-start;
        }

        @include media('desctop-grid')
        {
            padding: 160px 40px 0 40px;
        }

        @include media('d-only-s')
        {
            flex-direction: row;
        }
    }

    .section_block
    {
        width:         100%;
        //flex-basis:    100%;
        
        margin-bottom: 20px;

        &:nth-child(2)
        {

        }

        @include media('d-only-s')
        {
            width:      50%;
            max-width:  50%;
            min-width:  50%;

            flex-basis: 50%;
        }

    }

    .invitation_contacts
    {

        margin-bottom: 10px;

        @include media('d-only-s')
        {
            padding-left: 45px;
        }
    }

    .invitation_contact
    {
        .contact_content
        {
            @extend %other-font;

            display:         flex;
            align-items:     flex-start;
            justify-content: flex-start;

            position:        relative;

            font-size:       12px;
            line-height:     15px;

            @include media('desctop-grid')
            {
                font-size:   16px;
                line-height: 22px;
            }

            a
            {
                display:     inline;
                line-height: normal;
            }
        }

        dt
        {
            color:       $orange;
            font-weight: 700;
            min-width:   55px;

            @include media('m-iphone5')
            {
                min-width: 0;
            }
        }

        dd
        {
            color:       $gray-lightest;
            margin-left: 5px;
        }
    }

    .invitation_description
    {
        @extend %other-font;

        font-size:   15px;
        color:       $gray-lightest;

        padding-top: 10px;

        & > *
        {
            &:not(:last-child)
            {
                margin-bottom: 20px;
            }
        }

        .full_str
        {
            display: block;
        }

        .title
        {
            font-size: 16px;

            span
            {
                display:        block;
                text-transform: uppercase;
            }
        }
    }

    .section_title
    {
        @extend %section_subtitle;
        @extend %titles-shadow;
    }

    .section_description
    {
        @extend %section_description;

        margin: 5px 0 20px 0;

        &:before
        {
            background: $gray-lightest;
        }
    }

    .section_button
    {
        font-size: 0;

        @include media('d-only-s')
        {
            margin-left: 45px;
        }
    }

    .section_link
    {
        color:         $gray-lightest;

        @include go-button(-20px, -35px, 12px 53px);

        margin-bottom: 25px;
        margin-right:  18px;

        &.md_second
        {
            @include go-button(-20px, -35px, 12px 44px);
        }
    }
}

