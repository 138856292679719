.order
{

    @extend %std-transition;

    position:        fixed;
    top:             0;
    right:           0;
    left:            0;
    bottom:          0;

    visibility:      hidden;

    z-index:         999;

    display:         flex;
    align-items:     center;
    justify-content: center;

    background:      rgba(0, 0, 0, 0.97);

    opacity:         0;

    &.visible
    {
        opacity:    1;
        visibility: visible;

    }

    .order_close
    {
        position: absolute;
        right:    0;
        top:      0;

        padding:  15px;

        cursor:   pointer;

        @include media('t-ipad')
        {
            right: 30px;
            top:   30px;

            &:hover
            {
                & svg
                {
                    fill: $orange;
                }
            }
        }

        svg
        {
            @extend %std-transition;

            width:        22px;
            height:       22px;

            stroke-width: 2px;
            fill:         $white;
        }

    }

    .order_container
    {
        position:  relative;

        max-width: 690px;
        width:     100%;

        color:     $gray-lightest;
    }

    .order_title
    {
        color:          $orange;
        font-size:      27px;
        text-transform: uppercase;
        text-align:     center;

        @include media('t-ipad')
        {
            font-size: 54px;
        }
    }

    .order_subtitle
    {
        font-size:      18px;
        text-transform: uppercase;
        text-align:     center;

        margin-bottom:  16px;
        padding:        0 15px;

        @include media('t-ipad')
        {
            font-size:     43px;
            margin-bottom: 30px;
        }
    }

    @at-root .order_form
    {
        max-width: 385px;
        margin:    0 auto;
        width:     100%;

        padding:   0 15px;

        @include media('t-ipad')
        {
            padding: 0;
        }

        .order_field
        {
            position:      relative;
            width:         100%;
            border-bottom: 2px solid $gray-lightest;
            margin-bottom: 20px;

            @include media('t-ipad')
            {
                margin-bottom: 35px;
            }

            &.active
            {
                border-bottom: 2px solid $white;
            }
        }

        .order_input
        {
            @extend %other-font;

            display:    block;
            width:      100%;

            padding:    10px;

            color:      $white;

            font-size:  16px;

            border:     none;

            background: none;

            text-align: left;

            &:focus
            {
                outline: none;

            }

        }

        .order_label
        {
            @extend %other-font;
            @extend %std-transition;

            position:   absolute;
            top:        0;
            bottom:     0;
            right:      0;
            left:       0;

            padding:    9px 10px 0 10px;

            color:      #d4cfcd;

            font-size:  16px;

            transform:  translate3d(0, 0, 0) scale(1);

            text-align: left;

            &.active
            {

                top:       -20px;
                font-size: 12px;
            }
        }

        .order_form-button
        {
            width: 100%;

            @include media('t-ipad')
            {
                margin-top: 15px;
            }
        }

        .input_file
        {
            input
            {
                display: none;
            }
            label
            {
                display: block;
                width:   100%;
            }
        }

        @at-root .order_response
        {
            @extend %std-transition;

            position:        fixed;
            left:            0;
            right:           0;
            bottom:          0;
            top:             0;

            text-align:      center;

            display:         flex;
            align-items:     center;
            justify-content: center;
            flex-direction:  column;

            visibility:      hidden;

            z-index:         1000;

            opacity:         0;

            background:      rgba(0, 0, 0, 0.95);
            color:           $white;

            &.visible
            {
                opacity:    1;
                visibility: visible;
            }

            .response
            {
                margin-bottom: 35px;

                h3
                {
                    font-size: 25px;
                }

                p
                {
                    font-size: 20px;
                }
            }

            .order_response-text
            {
                & > *
                {
                    display:   block;
                    margin:    5px 0;

                    font-size: 18px;
                }
            }
        }
    }
}

.js-error
{
    position:   absolute;
    bottom:     -35px;
    left:       0;
    right:      0;

    text-align: center;

}

.order-button
{
    cursor: pointer;
}

.TZ
{
    .order_form-button
    {
        margin-top: 15px;
    }

    .dz-default.dz-message
    {
        width: 100%;

        span
        {
            width:      100%;
            padding:    10px;

            background: red;
        }
    }

    .dz-image
    {
        display: none;
    }

    .dz-default.dz-message span
    {
        margin-bottom: 15px;
        padding:       30px 10px;

        background:    none;
        border:        2px solid $gray-lightest;

        text-align:    center;
        color:         #d4cfcd;
        font-family:   'Roboto', sans-serif;
        font-weight:   400;
        font-size:     16px;

        cursor:        pointer;

        transition:    $transition-std;
    }

    .dz-progress, .dz-error-message, .dz-size, .dz-success-mark, .dz-error-mark
    {
        display: none;
    }

    .dz-drag-hover .dz-default.dz-message span, .dz-started .dz-default.dz-message span
    {
        border: 2px solid #fff;
        color:  #fff;
    }
}
