.addtocalendar
{
    margin-top: 15px;

    .atcb-list
    {
        width:      auto;
        left:       -2px;
        right:      -2px;
        top:        43px;

        background: none;

        .atcb-item
        {
            background: none;
        }

        .atcb-item-link
        {
            padding: 10px;

            &:hover
            {
                background: $orange;
            }
        }
    }
}